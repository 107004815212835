(function ($) {
  // Function to add info bubble to mobile menu
  function addInfoBubbleToMenu(mobileMenu) {
    // Check if menu already has our modification
    if (mobileMenu.classList.contains("bubble-added")) {
      return;
    }

    // Mark the menu as modified
    mobileMenu.classList.add("bubble-added");

    // Find the info bubble element to copy
    const infoBubble = document.querySelector(".lc-info-bubble");

    if (infoBubble) {
      // Create a new list item
      const newLi = document.createElement("li");
      newLi.className = "info-bubble-item";

      // Clone the info bubble and add it to the new list item
      const bubbleClone = infoBubble.cloneNode(true);

      // Remove the et_pb_section_1_tb_footer class from the cloned element
      bubbleClone.classList.remove("et_pb_section_1_tb_footer");
      bubbleClone.classList.remove("white-bg");
      bubbleClone.classList.remove("et_pb_section");

      newLi.appendChild(bubbleClone);

      // Insert the new list item at the beginning of the mobile menu
      if (mobileMenu.firstChild) {
        mobileMenu.insertBefore(newLi, mobileMenu.firstChild);
      } else {
        mobileMenu.appendChild(newLi);
      }

      console.log("Info bubble added to mobile menu");
    }

    // Set up submenu toggling
    setupSubmenuToggle(mobileMenu);
  }

  // Function to check for the mobile menu
  function checkForMobileMenu() {
    const mobileMenu = document.getElementById("mobile_menu");
    if (mobileMenu) {
      addInfoBubbleToMenu(mobileMenu);
    }
  }

  // Function to set up submenu toggle functionality with jQuery animations
  function setupSubmenuToggle(mobileMenu) {
    // Don't set up twice
    if (mobileMenu.classList.contains("submenu-setup")) {
      return;
    }

    // Mark menu as having submenu functionality
    mobileMenu.classList.add("submenu-setup");

    // Add styles for submenu functionality
    if (!document.getElementById("submenu-styles")) {
      const styleElement = document.createElement("style");
      styleElement.id = "submenu-styles";
      styleElement.textContent = `
          /* Hide all submenus by default */
          #mobile_menu .sub-menu {
            display: none;
          }
          
          /* Style for parent menu items with submenu */
          #mobile_menu .menu-item-has-children > a {
            position: relative;
          }
          
          /* Add '+' indicator for expandable menu items */
          #mobile_menu .menu-item-has-children > a::after {
            content: '+';
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            font-size: 20px;
            transition: transform 0.3s ease;
          }
          
          /* Change to '-' when submenu is open */
          #mobile_menu .submenu-open > a::after {
            content: '-';
          }
          
          /* Ensure adequate tap area on mobile */
          #mobile_menu .menu-item-has-children > a {
            padding-right: 40px !important;
          }
        `;
      document.head.appendChild(styleElement);
    }

    // Hide all submenus initially
    $(mobileMenu).find(".sub-menu").hide();

    // Add click event to all parent menu items
    $(mobileMenu)
      .find(".menu-item-has-children > a")
      .off("click")
      .on("click", function (e) {
        e.preventDefault();

        const $parent = $(this).parent();
        const $submenu = $parent.find("> .sub-menu");

        // Check if this menu is already open
        if ($parent.hasClass("submenu-open")) {
          // Close this submenu
          $parent.removeClass("submenu-open");
          $submenu.slideUp(300);
        } else {
          // Close any open siblings
          $parent.siblings(".submenu-open").removeClass("submenu-open").find("> .sub-menu").slideUp(300);

          // Open this submenu
          $parent.addClass("submenu-open");
          $submenu.slideDown(300);
        }
      });
  }

  // Check immediately in case the menu already exists
  checkForMobileMenu();

  // Set up a MutationObserver to watch for DOM changes
  const observer = new MutationObserver(function (mutations) {
    mutations.forEach(function (mutation) {
      // Check if nodes were added
      if (mutation.addedNodes && mutation.addedNodes.length > 0) {
        // Look for the mobile menu among added nodes
        for (let i = 0; i < mutation.addedNodes.length; i++) {
          const node = mutation.addedNodes[i];

          // Check if the node is an element
          if (node.nodeType === 1) {
            // Check if this is the mobile menu
            if (node.id === "mobile_menu") {
              addInfoBubbleToMenu(node);
              return;
            }

            // Check children for mobile menu
            const mobileMenu = node.querySelector("#mobile_menu");
            if (mobileMenu) {
              addInfoBubbleToMenu(mobileMenu);
              return;
            }
          }
        }
      }
    });

    // Also check directly after any DOM change
    checkForMobileMenu();
  });

  // Start observing the document body for changes
  observer.observe(document.body, {
    childList: true,
    subtree: true,
  });

  // Also handle menu display changes
  $(document).on("click", ".mobile_menu_bar", function () {
    // Wait a short time for the menu to appear
    setTimeout(checkForMobileMenu, 100);
  });
})(jQuery);
